/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface TokenResponse {
  accessToken: string;
  /** @default "Bearer" */
  tokenType: string;
  expiresIn: number;
  refreshToken?: string;
  idToken?: string;
}

export interface UserInfo {
  userId: string;
  fiscalCode: string;
  familyName?: string;
  name?: string;
  email?: string;
}

export interface ZendeskAssistanceTokenResponse {
  assistanceToken: string;
  /** URL of the Help Center to return */
  returnTo: string;
  /** URL of Zendesk endpoint */
  actionUrl: string;
}

export interface TransactionsListDTO {
  transactions?: TransactionDTO[];
  /** @format int32 */
  currentPage?: number;
  /** @format int32 */
  itemsForPage?: number;
  /** @format int32 */
  totalItems?: number;
  /** @format int32 */
  totalPages?: number;
}

export interface TransactionDTO {
  transactionId?: string;
  payeeName?: string;
  payeeTaxCode?: string;
  /** @format int64 */
  amount?: number;
  /**
   * @format date-time
   * @example "2024-05-31T13:07:25Z"
   */
  transactionDate?: string;
  isCart?: boolean;
  paidByMe?: boolean;
  registeredToMe?: boolean;
}

export interface NoticesListDTO {
  notices?: NoticeDTO[];
}

export interface NoticeDTO {
  eventId: string;
  payeeName?: string;
  payeeTaxCode: string;
  /** @format int64 */
  amount: number;
  /**
   * @format date-time
   * @example "2024-05-31T13:07:25Z"
   */
  noticeDate: string;
  isCart: boolean;
  paidByMe: boolean;
  registeredToMe: boolean;
}

export interface TransactionDetailsDTO {
  infoTransaction?: InfoTransactionDTO;
  carts?: CartItemDTO[];
}

export interface InfoTransactionDTO {
  transactionId?: string;
  authCode?: string;
  rrn?: string;
  /**
   * @format date-time
   * @example "2024-05-31T13:07:25Z"
   */
  transactionDate?: string;
  pspName?: string;
  walletInfo?: WalletInfoDTO;
  paymentMethod?: 'BBT' | 'BP' | 'AD' | 'CP' | 'PO' | 'OBEP' | 'JIF' | 'MYBK' | 'PPAL' | 'UNKNOWN';
  payer?: UserDetailDTO;
  /** @format int64 */
  amount?: number;
  /** @format int64 */
  fee?: number;
  origin?: 'INTERNAL' | 'PM' | 'NDP001PROD' | 'NDP002PROD' | 'NDP003PROD' | 'UNKNOWN';
}

export interface CartItemDTO {
  subject?: string;
  /** @format int64 */
  amount?: number;
  payee?: UserDetailDTO;
  debtor?: UserDetailDTO;
  refNumberValue?: string;
  refNumberType?: string;
}

export interface UserDetailDTO {
  name?: string;
  taxCode?: string;
}

export interface WalletInfoDTO {
  accountHolder?: string;
  brand?: string;
  blurredNumber?: string;
  maskedEmail?: string;
}

export interface PaymentNoticesListDTO {
  paymentNotices?: PaymentNoticeDTO[];
}

export interface PaymentNoticeDTO {
  /** Unique ID of the Debt Position (for positions originating from ACA the IUPD starts with ACA_) */
  iupd: string;
  /** Tax code of the person to whom the Debt Position is registered */
  debtorTaxCode: string;
  /** Full name of the person to whom the Debt Position is registered */
  debtorFullName: string;
  /** Type of subject to whom the Debt Position is registered (Will be F (Physical) or G(Legal)) */
  debtorType: string;
  /** Tax code of the Creditor Body */
  paTaxCode: string;
  /** Company name of the Creditor Body */
  paFullName: string;
  /** Date of entry of the Debt Position */
  insertedDate: LocalDateTime;
  /** Date of publication of the Debt Position. In the case of Positions created by ACA it corresponds to the insertion date. */
  publishDate: LocalDateTime;
  /** Start date of validity of the Debt Position. if set to null it goes directly to valid when publishing */
  validityDate: LocalDateTime;
  /**
   * State of the Debt Position. Will be
   * VALID
   *  or
   * PARTIALLY_PAID
   */
  status: PaymentNoticeStatus;
  /** Date of update of the Debt Position */
  lastUpdateDate: LocalDate;
  /** Array structure containing any payment options (there always exists at least 1) */
  paymentOptions: PaymentOptionDTO[];
}

/**
 * @format date
 * @example "2022-03-10"
 */
export type LocalDate = string;

/**
 * @format date-time
 * @example "2022-03-10T12:15:50"
 */
export type LocalDateTime = string;

export enum PaymentNoticeStatus {
  VALID = 'VALID',
  PARTIALLY_PAID = 'PARTIALLY_PAID'
}

export interface PaymentOptionDTO {
  /** Description of the payment option */
  description?: string;
  /**
   * Number of installments that make up the payment option, if equal to 1 it is a single payment
   * @format int32
   */
  numberOfInstallments: number;
  /**
   * total amount for the payment option
   * @format int64
   */
  amount?: number;
  /** Is the date by which the Payment option is payable. */
  dueDate?: LocalDateTime;
  /** Indicates whether the OP is part of an installment plan */
  isPartialPayment?: boolean;
  /** Indicates, if set to true, in the case of PD created on GPD, that once the expiration date (dueDate ) has passed the PD is automatically set to the expired status */
  switchToExpired: boolean;
  /** Array structure containing the installments that make up the payment option (there always exists at least 1) */
  installments: InstallmentDTO[];
}

export interface InstallmentDTO {
  /** Notice Code */
  nav: string;
  /** Unique identifier */
  iuv: string;
  /** Tax code of the Creditor Body */
  paTaxCode: string;
  /** Company name of the Creditor Body */
  paFullName: string;
  /**
   * Payment Option Amount
   * @format int64
   */
  amount: number;
  /** Description of the OP (e.g. "SISA - 741T, 942T - Checks without authorization or funding") */
  description: string;
  /** Is the date by which (TO) the Payment option is payable. */
  dueDate: LocalDateTime;
  /** Not currently used in any logic. The purpose of this date will be to give the possibility to specify a time period after the dueDate within which a payment option, even if expired, will still be payable. */
  retentionDate?: LocalDateTime;
  /** date of insertion of the OP */
  insertedDate: LocalDateTime;
  /**
   * corresponds to the SEND notification costs
   * @format int64
   */
  notificationFee?: number;
  /** Status of the OP */
  status: PaymentOptionStatus;
  /** OP update date */
  lastUpdatedDate: LocalDateTime;
}

export enum PaymentOptionStatus {
  PO_UNPAID = 'PO_UNPAID',
  PO_PAID = 'PO_PAID',
  PO_PARTIALLY_REPORTED = 'PO_PARTIALLY_REPORTED',
  PO_REPORTED = 'PO_REPORTED'
}

export interface ErrorDTO {
  error:
    | 'generic_error'
    | 'transaction_not_found_error'
    | 'receipt_not_found_error'
    | 'invalid_amount'
    | 'invalid_date'
    | 'invalid_email'
    | 'invalid_request';
  error_description?: string;
}
